<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-button variant="success" v-b-modal.new-modal>ثبت سوال جدید</b-button>
        <b-card title="سوالات متداول" class="mt-3">
            <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>
                <template v-slot:cell(answer)="data">
                    <p class="mb-0" style="max-width: 500px;">{{data.item.answer}}</p>
                </template>

                <template v-slot:cell(edit)="data">
                    <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
                </template>
                
                <template v-slot:cell(delete)="data">
                    <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i
                            class="fa fa-close"></i></button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>

        <pagination :limit="2" v-if="items.data" :data="items.data" @pagination-change-page="loadItems">
        </pagination>

        <b-modal id="new-modal" title="ثبت سوال" hide-footer>
            <div>
                <form @submit.prevent="newItem" id="new-item">
                    <b-form-group>
                        <label for="question"> سوال <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-textarea id="question" name="question" :disabled="disabled"></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                        <label for="answer"> جواب <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-textarea id="answer" name="answer" :disabled="disabled"></b-form-textarea>
                    </b-form-group>
                    <!-- <div class="form-group">
                        <label class="custom-switch">
                            <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status"
                                :disabled="disabled">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">وضعیت</span>
                        </label>
                    </div> -->
                    <div class="clearfix">
                        <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
                        <b-button class="float-left" variant="white" type="button"
                            @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
                    </div>
                </form>
            </div>
        </b-modal>

        <b-modal id="edit-modal" title="ویرایش سوال" hide-footer>
            <div v-if="edit.id">
                <form @submit.prevent="itemEdited" id="edit-item">
                    <b-form-group  label-for="question">
                        <label for="question"> سوال <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-textarea id="question" name="question" v-model="edit.question" :disabled="disabled">
                        </b-form-textarea>
                    </b-form-group>

                    <b-form-group>
                        <label for="answer"> جواب <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-textarea id="answer" name="answer" v-model="edit.answer" :disabled="disabled">
                        </b-form-textarea>
                    </b-form-group>

                  
                    <!-- <div class="form-group">
                        <label class="custom-switch">
                            <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status"
                                :disabled="disabled">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">وضعیت</span>
                        </label>
                    </div> -->
                    <div class="clearfix">
                        <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
                        <b-button class="float-left" variant="white" type="button"
                            @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
                    </div>
                    <input type="hidden" value="PUT" name="_method">
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import mixins from './mixins/mixins'

    export default {
        mixins: [mixins],
        data() {
            return {
                url: '/api/admin/seller_faqs',
                title: ' سوالات متداول فروشنده',
                faq: [],
                table_fields: [{
                        key: 'id',
                        label: 'شناسه '
                    },
                    {
                        key: 'question',
                        label: 'سوال'
                    },
                    {
                        key: 'answer',
                        label: 'جواب'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ ثبت'
                    },
                    {
                        key: 'edit',
                        label: 'ویرایش '
                    },
                    {
                        key: 'delete',
                        label: 'حذف'
                    }
                ],
                selected_status: '1',
            }
        },
        methods: {
            loadItems(page = 1) {
            this.show = true
            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page).then(response => {
                this.items = response
                this.show = false
            })
        },
        },
        created() {
            this.loadItems()
        }
    }
</script>